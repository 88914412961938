.career-image img {
    width: 56%;
    height: auto;
    max-width: 550px;
    object-fit: contain;
    margin-right: 70px;
  margin-top: 110px;
}
.benefits-section {
    background-color: #0f2747;
    padding: 20px 10px;
    color: white;
  }
  
  .container1 {
    display: flex;
    align-items: flex-start;
    justify-content:center; 
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .container1 h2{
    text-align: center;
  }
  
  .benefits-text {
    flex: 1;
    max-width: 500px; 
  
  }
  
  .benefits-text h3 {
    font-size: 1.5rem;
    color: #a1c4ff;
    margin-bottom: 10px;
  }
  
  .benefits-text h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .benefits-text p {
    font-size: 1.1rem;
    margin-bottom: 40px;
  }
  
  .benefits-cards {
    display: flex;
    flex: 0.4; 
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card1 {

    padding: 20px;
    width: 220px;
    text-align: left;
    border-radius: 8px;
  }
  
  .icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .card1 h4 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card1 p {
    font-size: 0.9rem;
    color: #b0c7e3;
  }
  .career-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    padding: 0px 0; 
  }
  
  .career-header h3 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .career-header h2 {
    font-size: 2.3rem;
    margin: 0;
  }
  
  .career-header p {
    font-size: 1rem;
    margin: 0 0 40px 0;
    max-width: 600px; 
  }
  
  .career-openings {
    background-color: rgba(216, 238, 247, 0.945);
    padding: 60px 20px;
  }
  .career-image {
    text-align: center;
    margin-top: 20px;
  }
  
  .container1 {
    text-align: center;
    margin-bottom: 30px;
    margin-left: 60px;
  }
  
  .form-image-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; 
    gap: 40px; 
    width: 100%; 
  }
  
  .career-form {
    margin-top: 10px;
    flex: 1; 
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    gap: 25px; 
  }
  
  .career-form input {
    padding: 10px;
    font-size: 1rem;
    width: 100%; 
  }
  
  .career-form .submit-btn {
    background-color: #ffcc00;
    border: none;
    padding: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    width: 100%;
  }
  
  .career-images{
    flex: 1; 
    text-align: right; 
  }
  
  .career-images img {
    max-width: 79%;
    height: auto;
    border-radius: 8px;
    display: block; 
    margin-top: 7px;
    }
    .submit-btn {
    background-color: #ffb74d;
    color: white;
    padding: 12px 30px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #ffa726;
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .container1 {
      flex-direction: column;
    }
  
    .benefits-text {
      margin-bottom: 30px;
    }
  
    .benefits-cards {
      justify-content: center;
    }
  }
  


  @media (max-width: 768px) {
    .benefits-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .card1 {
      width: 100%;
      max-width: 500px;
    }
  }
  

  @media (max-width: 768px) {
    .form-image-wrapper {
      flex-direction: column;
      align-items: center;
    }
  
    .career-image{
      text-align: center;
      margin-top: 20px;
    }
  }
  