.hero-section {
  position: relative;
  background-color: #f5f5f5;
  padding: 50px 20px;
  text-align: center;
  animation: fadeInUp 1.5s ease-in-out;
}

.hero-text {
  animation: fadeInUp 1.5s ease-in-out;
}

.direct-image {
  animation: fadeInUp 1.5s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn-primary {
  margin-top: 20px;
  padding: 10px 20px;
  background-color:#087cf9 ;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.direct-desk {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: linear-gradient(135deg,#cfe2ff,#c4cad1);
  padding: 50px 20px;
  text-align: center;
}

.direct-card {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  animation-duration: 1.5s;
}

.direct-text h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  position: relative;
}

.direct-text h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background-color: #007bff;
  margin: 10px auto;
}

.direct-text p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
}



@keyframes fadeInUp {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.animate__fadeInUp {
  animation: fadeInUp 1.5s ease-in-out;
}

