/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}




.product-gallery-container {
  padding: 40px;
  text-align: center;
  background-color: #f0f8ff;
}

hr {
  border: none; 
  height: 4px; 
  background-color: #ffcc00; 
  margin: 10px 0; 

}


.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px; 
  max-width: 1200px; 
  margin: 0 auto;
  justify-items: center;
  align-items: center;
}



h2{
  text-align: center;
}
/* Hero Section */
.hero-section {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  background: linear-gradient(180deg, #9eddf8, #00bfffaf);
  position: relative;
}

.hero-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.hero-text {
  flex: 1;
  max-width: 50%;
  color: #fff;
  padding-right: 20px;
  
}

.hero-text h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.shop-now-btn {
  background-color: #FFD700;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; 
}

.shop-now-btn:hover {
  background-color: #ffcc00;
  transform: scale(1.05); 
}


.hero-image ,
  .hero-images,.career-image,.contact-image {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
}
hr {
  border: none; 
  height: 4px; 
  background-color: #ffcc00; 
  margin: 10px 0; 
}
.hero-image img {
  width: 100%;
  height: auto;
  max-width: 500px;
  object-fit: contain;
}
.hero-images img {
  width: 100%;
  height: auto;
  max-width: 710px;
  object-fit: contain;
  margin-right: 70px;
margin-top: 125px;
}
.hero-images1 img {
  width: 100%;
  height: auto;
  max-width: 550px;
  object-fit: contain;
  margin-right: 70px;
margin-top: 125px;
}
/* About Us Section */
.about-us {
  padding: 80px;
  background-color: #f4f4f4;
}

.about-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.about-image img {
  width: 450px;
  height: auto;
  border-radius: 10px;
}

.about-text h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2c3e50;
  font-weight: bold;
}

.about-text p {

  line-height: 1.9;
  padding: 40px;
  text-align: justify;
  font-size: 1.1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
}
.read-more-btn {
  display: inline-block;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 40px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #0056b3;
}
/* Best Products Section */
.best-products {
  padding: 30px;
  background-color: rgb(203, 230, 242);
}

.best-products h2 {
  margin-bottom: 40px;
  font-size: 28px;
}

.product-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  display: flex;
  gap: 20px;
}

.product-card {
  width: 250px;
  height: 300px;
  text-align: center;
  background-color: white;
  padding: 5px;
  opacity: 0;
  transform: translateY(20px); 
  animation: fadeIn 0.5s forwards; 
}
@keyframes fadeIn {
  to {
    opacity: 1; 
    transform: translateY(0); 
  }
}

.product-card:nth-child(1) { animation-delay: 0.1s; }
.product-card:nth-child(2) { animation-delay: 0.2s; }
.product-card:nth-child(3) { animation-delay: 0.3s; }
.product-card:nth-child(4) { animation-delay: 0.4s; }
.product-card:nth-child(5) { animation-delay: 0.5s; }
.product-card:nth-child(6) { animation-delay: 0.6s; }

.product-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.product-gallery {
  padding: 30px 0; 
  background-color: #184363;
  
}

.product-card {

  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;

  transition: box-shadow 0.3s;
}
.product-card  img{
  height: 100%;
  width: 100%;
}


.carousel-control-next-icon ,.carousel-control-prev-icon{
  background-color: grey;
  margin-top: 50px;
 font-size: 10px;
 border-radius: 50%;
}

/* Footer Section */
.footer {
  background-color: #184363;
  color: white;
  padding: 60px 50px;
}

.footer-logo {
  width: 100px; 
  margin-bottom: 20px; 
}

.social-links {
  margin-top: 10px; 
}

.social-links a {
  margin-right: 15px; 
  color: #FFD700; 
  text-decoration: none;
}

.social-links a:hover {
  color: white; 
}


/* Media Queries for Responsiveness */
@media (max-width: 1200px) {


  .hero-section {
    height: 500px;
  }

  .hero-text h1 {
    font-size: 35px;
  }

  .product-card {
    width: 200px;
    height: 250px;
  }
}

@media (max-width: 992px) {
  .hero-section {
    height: 500px;
    padding: 0 20px;
  }

  .hero-text {
    max-width: 100%;
    text-align: center;
  }

  .hero-text h1 {
    margin-top: 70px;
    font-size: 30px;
  }
  .hero-text p {
    margin-top: 70px;
    font-size: 20px;
  }


  .about-content {
    flex-direction: column;
  }

  .about-image img {
    width: 100%;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer .company-info, .footer .quick-links, .footer .contact-info {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
 
  .hero-text h1 {
    margin-top: 100px;
    font-size: 24px;
  }

  .hero-text p {
    margin-top: 50px;
    font-size: 12px;
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* Two products per row on smaller screens */
}

  .clients {
    padding: 50px 20px;
  }

  .testimonial {
    margin: 0 10px;
  }
}

@media (max-width: 576px) {
  .hero-section {
    padding: 0 10px;
   
  }

  .hero-text h1 {
    margin-top: 100px;
    font-size: 24px;
  }

  .hero-text p {
    margin-top: 50px;
    font-size: 12px;
  }

  .shop-now-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .footer h4 {
    font-size: 16px;
  }

  .footer p {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
 
} .product-grid {
      grid-template-columns: 1fr; /* One product per row on very small screens */
  }
