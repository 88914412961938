.product-gallery-container{
  background: rgb(207, 229, 238);
  padding: 20px; 
    text-align: center;

}
.product-grid {
  display: grid; 
  grid-template-columns: repeat(auto-fill, minmax(300px, 3fr));
  gap: 15px;
  }
  
  .product-card1 {
    width: 350px;
    height: 300px;
    text-align: center;
    background: linear-gradient(to bottom, #e9e5c0, #bae8fa);
    padding: 5px;
    display: flex; 
    justify-content: center; 
    align-items: center; 

  }



  
@media (max-width: 768px) {
  .hero-section {
      flex-direction: column; 
      align-items: center; 
  }

  .hero-text h1 {
      font-size: 2rem; 
  }
}
