/* Base styles for the navbar */
.navbar {
    background-color:#184363;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 100;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo img {
    height: 50px;
}

/* Navigation links styles */
.nav-links {
    list-style: none;
    display: flex;
    gap: 2rem;
    transition: transform 0.3s ease-in-out;
}

.nav-links li {
    text-transform: uppercase;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-weight: 500;
}

.nav-links a:hover {
    color: #ffcc00;
}

 
.active-link {
    font-weight: bold;
    color: #ffcc00; 
    border-bottom: 2px solid #ffcc00; 
}


.burger-icon {
    display: none;
    cursor: pointer;
    font-size: 2rem;
    color: white;
}


@media (max-width: 768px) {
    .navbar{
        background-color: #184363;
    }
    .burger-icon {
        display: block; 
        color: white;
        font-size: 2rem;
        z-index: 1001; 
        position: relative; 
        cursor: pointer;
    }

    .nav-links {
        position: absolute;
        top: 70px; 
        left: 0;
        width: 100%;
        height: 100vh;
        background-color:#184363;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 99;
    }

 
    .nav-links.open {
        transform: translateY(0);
    }

    .nav-links li {
        margin: 10px 0;
        font-size: 1.5rem;
    }

    .nav-links a {
        font-size: 1.4rem;
        color: white;
    }
}
@media (min-width: 769px) {
    .navbar{
        background-color: #184363;
    }
    .burger-icon {
        display: none;
    }
}


button,
a {
    transition: all 0.2s;
}

button:focus,
a:focus {
    outline: none;
}
